// https://bulma.io/documentation/elements/notification/#javascript-example
;(() => {
  const notificationSelector = ".js-notification"
  const deleteSelector = ".js-notificationDelete"

  document.addEventListener("DOMContentLoaded", () => {
    ;(document.querySelectorAll(deleteSelector) || []).forEach(($delete) => {
      const $notification = $delete.closest(notificationSelector)
      $delete.addEventListener("click", () => {
        $notification.parentNode.removeChild($notification)
      })
    })
  })
})()
