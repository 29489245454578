// Some very simple functionality to toggle the visibility of some elements
// based on changes to a form field.
document.addEventListener("DOMContentLoaded", () => {
  Array.from(document.getElementsByClassName("js--toggler")).forEach((el) => {
    const toggler = JSON.parse(el.dataset.toggler)
    const togglerItems = document.querySelectorAll(`[data-toggler-parent="${toggler.id}"]`)
    const toggle = () => {
      togglerItems.forEach((item) => {
        const shouldShow =
          item.dataset.togglerShow === el.value || (el.value === "" && toggler.display_on_empty)
        const className = toggler.toggleClass || "is-hidden"
        item.classList[shouldShow ? "remove" : "add"](className)
      })
    }
    el.addEventListener("change", toggle)
    toggle()
  })
})
